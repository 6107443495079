<template>
  <div class="app-design" v-loading="loading">
    <el-card v-for="(item, index) in list" :key="item.id">
      <div slot="header" class="flex-between">
        <span class="card-title flex">{{item.name}}</span>
      </div>
      <div class="card-content">
        <span class="card-time">更新时间：{{item.update_time || '暂无'}}</span>
        <span class="card-time"></span>
        <div class="card-btn-group">
          <el-button @click="toDesign()" size="small">前往</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      list: [],
    };
  },
  created() {
    this.list.push({
      name: '大数据可视化分析平台',
      id: 1,
      update_time: 0
    })
    setTimeout(() => {
      this.loading = false
    }, 100)
  },
  methods: {
    toDesign() {
      let routerData = this.$router.resolve({
        name: "BigDataOverview",
        params: {
          tab: 'home'
        },
      });
      window.open(routerData.href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
  .app-design {
    margin: -$space;
    padding: 4px;
    background-color: #f5f6f7;
    min-height: 100%;
    flex: 1;

    .el-card,
    ::v-deep.el-card {
      & + .el-card {
        margin-top: 24px;
      }

      .el-card__header {
        padding: 24px 32px 20px;
      }
    }

    .card-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      display: inline-flex;

      &::before {
        content: "";
        background-color: $primary;
        width: 3px;
        height: 16px;
        display: inline-block;
        margin-right: 8px;
      }
    }

    .card-switcher {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      line-height: 16px;
    }

    .card-content {
      display: flex;
      justify-content: space-between;

      .card-time {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 14px;
      }

      .card-btn-group {
        margin-top: 6px;
      }

      .no-bind {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 14px;
        height: 62px;
        margin: 0 auto;

        .el-link,
        a {
          margin-left: 5px;
        }
      }
    }
  }
</style>

